import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NotFoundPage = props => {
  const pageTitle = "Not Found"
  const pageSlug = "notfound"

  return (
    <Layout location={props.location}>
      <SEO pageTitle={pageTitle} pageSlug={pageSlug} />
      <div className="container" style={{ marginTop: "5em" }}>
        <h1>{pageTitle}</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
